<template>
  <q-drawer v-model="show" elevated side="right">
    <div class="column items-stretch">
      <q-stepper
        ref="stepper"
        v-model="currentStep"
        vertical
        class="stepper row full-width no-shadow"
        header-nav
      >
        <q-step
          name="ondemand-carclub-results"
          default
          :title="$t('vehicles_in_the_area')"
          :caption="$t('select_vehicle')"
          :done="navigatable.includes('ondemand-carclub-results')"
          :header-nav="navigatable.includes('ondemand-carclub-results')"
        >
          <q-stepper-navigation>
            <q-btn
              :disable="loading || !selectedVehicle"
              @click="$router.push('/on-demand/carclub/window')"
            >
              {{ $t('continue') }}
            </q-btn>
          </q-stepper-navigation>
        </q-step>
        <q-step
          name="ondemand-carclub-window"
          default
          :title="$t('confirm.time_window')"
          :caption="$t('confirm.time_selection')"
          :done="navigatable.includes('ondemand-carclub-window')"
          :header-nav="navigatable.includes('ondemand-carclub-window')"
        >
          <q-stepper-navigation>
            <q-btn
              :disable="loading || notFullyAvailable"
              @click="$router.push({name: 'ondemand-carclub-summary', params: {token: selectedVehicle.token}})"
            >
              {{ $t('continue') }}
            </q-btn>
          </q-stepper-navigation>
        </q-step>
        <q-step
          name="ondemand-carclub-summary"
          :title="$t('summary')"
          :caption="$t('review_and_submit')"
          :done="navigatable.includes('ondemand-carclub-summary')"
          :header-nav="navigatable.includes('ondemand-carclub-summary')"
        />
        <q-step
          name="ondemand-carclub-payment-card"
          :title="$t('payment')"
          :done="navigatable.includes('ondemand-carclub-payment-card')"
          :header-nav="navigatable.includes('ondemand-carclub-payment-card')"
        />
        <q-step
          name="ondemand-carclub-complete"
          :title="$t('confirmation')"
          :done="navigatable.includes('ondemand-carclub-complete')"
          :header-nav="navigatable.includes('ondemand-carclub-complete')"
        />
      </q-stepper>
      <div v-if="this.$route.name !== 'ondemand-carclub-summary'" class="form">
        <q-item-label class="search-header" header>
          {{ $t('carclub.narrow_results') }}
        </q-item-label>
        <on-demand-form
          :compact="true"
          :time-only="navigatable.includes('ondemand-carclub-results')"
          @change="getResults"
        />
      </div>
    </div>
  </q-drawer>
</template>

<script type="text/javascript">
import _ from 'lodash'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
import onDemandForm from './form.vue'
export default {
  name: 'OndemandCarclubSidebar',
  components: {
    onDemandForm
  },
  data () {
    return {
      show: !this.$q.platform.is.mobile,
      total: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'carClub/loading',
      selectedVehicle: 'carClub/selectedVehicle',
      searchLocation: 'carClub/searchLocation',
      searchStartDateTime: 'carClub/searchStartDateTime',
      searchEndDateTime: 'carClub/searchEndDateTime',
      notFullyAvailable: 'carClub/notFullyAvailable',
      partner: 'partner'
    }),
    location: {
      get () {
        return this.searchLocation ? this.searchLocation.label : null
      },
      set (val) {
        this.$store.dispatch('carClub/setSearchLocation', val)
        this.getResults()
      }
    },
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    },
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-carclub-complete':
        availablePaths.push('ondemand-carclub-payment-card')
      case 'ondemand-carclub-payment-card':
        availablePaths.push('ondemand-carclub-summary')
      case 'ondemand-carclub-summary':
        availablePaths.push('ondemand-carclub-window')
      case 'ondemand-carclub-window':
        availablePaths.push('ondemand-carclub-results')
        break
      }
      return availablePaths
    }
  },
  methods: {
    getResults: _.debounce(function () {
      if (!this.location) return
      loading.start({
        message: this.$t('loading.searching.carclub'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      return this.$store.dispatch('carClub/search')
        .then(res => {
          loading.stop()
        })
        .catch(() => {
          loading.stop()
        })
    }, 1500)
  }
}
</script>

<style lang="stylus" scoped>
.form
  padding: 0 16px

.stepper
  border-radius 0
  border-bottom 1px solid grey

small
  height 90%
  display block
  height 36px

.search-header
  padding-left 0
</style>
