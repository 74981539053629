<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code flex items-center justify-center" />
    <div class="column container">
      <div class="row">
        <q-card
          square
          class="bg-white"
        >
          <q-card-section :class="`bg-primary text-white text-uppercase`">
            {{ $t('content_type.carclub') }}
            <m-image :src="require('assets/enterprise_carclub_white.png')" width="100px" class="float-right" />
          </q-card-section>
          <q-separator />
          <q-card-section class="column">
            <on-demand-form v-if="canCarClub" />
            <div v-else class="notice">
              <h6>{{ $t('carclub.credentials.required_2') }}</h6>
              <q-btn
                :label="$t('carclub.membership.sign_in')"
                color="primary"
                :to="{hash: '/user-settings/carclub'}"
              />
              <h6 v-if="['bp', 'gohi'].includes(partner.slug)">
                {{ $t('carclub.membership.register_here') }}
              </h6>
              <h6 v-else>
                {{ $t('carclub.membership.register_directly') }}
              </h6>
              <q-btn
                v-if="partner.pages.carclub"
                :label="$t('carclub.membership.sign_up')"
                :color="partner.pages.carclub.button.color"
                @click="openURL(`${partner.pages.carclub.button.url}`)"
              />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-if="canCarClub" v-model="people" />
        </div>
      </div>
      <div class="row cta">
        <q-btn
          v-if="canCarClub"
          :disabled="disabled"
          size="lg"
          color="primary"
          class="full-width"
          @click="getResults"
        >
          {{ $tc('find.car') }}
        </q-btn>
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { openURL } from 'quasar'
import { MImage, MUnifiedUsers } from 'components/'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import onDemandForm from './form.vue'
import date from 'utils/date-time'

const { addToDate, roundMinutesUp } = date

export default {
  components: {
    onDemandForm,
    MUnifiedUsers,
    MImage
  },
  mixins: [authentication],
  computed: {
    ...mapGetters({
      userLookup: 'userLookup',
      searchLocation: 'carClub/searchLocation',
      searchStartDateTime: 'carClub/searchStartDateTime',
      searchEndDateTime: 'carClub/searchEndDateTime',
      traveller: 'carClub/traveller',
      requester: 'carClub/requester',
      partner: 'partner'
    }),
    people: {
      get () {
        return {
          requester: this.requester,
          traveller: this.traveller
        }
      },
      set ({ requester, traveller }) {
        this.$store.dispatch('carClub/setRequester', requester)
        if (traveller && !traveller.has_carclub) {
          this.$q.dialog({
            title: this.$t('carclub.credentials.not_set'),
            message: this.$t('person_needs_to_add_credentials', { person: traveller.label })
          })
          traveller = null
        }
        this.$store.dispatch('carClub/setTraveller', traveller)
      }
    },
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    },
    canCarClub () {
      return this.userLookup.has_carclub || this.hasPermission('can.book.for.org.members')
    },
    travellerCanCarClub () {
      return this.people.traveller && this.people.traveller.has_carclub
    }
  },
  created () {
    const searchLocation = this.$route.params.origin || this.$route.query.origin
    this.$store.dispatch('carClub/reset')
    if (typeof searchLocation === 'object') {
      this.$store.dispatch('carClub/setSearchLocation', searchLocation)
    }
    this.$store.dispatch('carClub/setSearchStartDateTime', roundMinutesUp(addToDate(date.newDate(), { minutes: 0 }), 15))
    this.$store.dispatch('carClub/setSearchEndDateTime', roundMinutesUp(addToDate(this.searchStartDateTime, { minutes: 30 })))
  },
  methods: {
    openURL,
    getResults () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$router.push('/on-demand/carclub/results')
      } else {
        this.$q.notify({ type: 'negative', message: this.$t('input.pickup_location') })
      }
    }
  },
  validations: {
    searchLocation: { required },
    searchStartDateTime: { required },
    searchEndDateTime: { required },
    requester: { required },
    traveller: { 'has_carclub': { isTrue: (value) => value === true } }
  }
}
</script>

<style lang="stylus" scoped>
.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background-image url('~assets/backgrounds/cc.jpg')
  background-position: 0 34%;
  background-size: cover;
  text-overflow: clip;

.column.container
  margin-top -72px

.q-card
  width 80vw
  max-width 600px

.notice
  display grid
  grid-auto-rows auto
  grid-row-gap 20px
  text-align center

  *
    margin auto
</style>
