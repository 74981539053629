<template>
  <div class="row q-col-gutter-sm">
    <div class="col-12">
      <m-location-search
        v-if="!timeOnly"
        v-model="location"
        :label="$t('pick_up_location')"
      />
    </div>
    <div class="col-6">
      <date-field
        v-model="startDateTime"
        :min="minStart"
        :format="compact ? 'yyyy/LL/dd' : undefined"
        :label="$t('date.start')"
      />
    </div>
    <div class="col-6">
      <time-field
        v-model="startDateTime"
        :min="minStart"
        :minute-step="15"
        :label="$t('time.start')"
      />
    </div>
    <div class="col-6">
      <date-field
        v-model="endDateTime"
        :min="minEnd"
        :max="maxEnd"
        :format="compact ? 'yyyy/LL/dd' : undefined"
        :label="$t('date.end')"
      />
    </div>
    <div class="col-6">
      <time-field
        v-model="endDateTime"
        :min="minEnd"
        :max="maxEnd"
        :minute-step="15"
        :label="$t('time.end')"
      />
    </div>
  </div>
</template>

<script>
import { MLocationSearch, timeField, dateField } from 'components/'
import { mapGetters } from 'vuex'
import date from 'utils/date-time'

const { addToDate, roundMinutesUp } = date

export default {
  components: {
    timeField,
    dateField,
    MLocationSearch
  },
  props: ['compact', 'timeOnly'],
  computed: {
    ...mapGetters({
      searchLocation: 'carClub/searchLocation',
      searchStartDateTime: 'carClub/searchStartDateTime',
      searchEndDateTime: 'carClub/searchEndDateTime'
    }),
    minStart () {
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 15 }), 15)
    },
    minEnd () {
      return addToDate(this.startDateTime, { minutes: 30 })
    },
    maxEnd () {
      return addToDate(this.startDateTime, { days: 7 })
    },
    location: {
      get () {
        return this.searchLocation ? this.searchLocation : null
      },
      set (val) {
        this.$store.dispatch('carClub/setSearchLocation', val)
        this.$emit('change')
      }
    },
    startDateTime: {
      get () {
        return this.searchStartDateTime
      },
      set (val) {
        this.$store.dispatch('carClub/setSearchStartDateTime', date.newDate(val))
        this.$emit('change')
      }
    },
    endDateTime: {
      get () {
        return this.searchEndDateTime
      },
      set (val) {
        this.$store.dispatch('carClub/setSearchEndDateTime', date.newDate(val))
        this.$emit('change')
      }
    }
  },
  watch: {
    startDateTime (val) {
      if (this.startDateTime > date.subtractFromDate(this.endDateTime, { minutes: 15 })) {
        this.endDateTime = date.addToDate(this.startDateTime, { minutes: 30 })
      }
    }
  }
}

</script>

<style>

</style>
