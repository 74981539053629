<template>
  <q-card :class="selected ? 'bg-selected' : 'bg-white'" class="relative-position" @click.native="select">
    <q-card-section class="vehicle">
      <div class="details row">
        <div class="col-3 car-image">
          <m-image
            :src="vehicle.image"
            :fallback="fallback"
          />
        </div>
        <div class="col-5">
          <q-item-label label class="vehicle-title">
            {{ vehicle.year }} {{ vehicle.transmission }} {{ vehicle.make }} {{ vehicle.model }}
          </q-item-label>
          <q-item-label label>
            <small>{{ $t('distance_away', { distance: vehicle.distance.text }) }} - {{ vehicle.location.description }}</small>
          </q-item-label>
        </div>
        <div class="col-3 column justify-between text-right">
          <div class="q-pr-sm">
            <img :src="require('assets/enterprise_carclub.png')" width="100%" style="max-width: 100px">
          </div>
          <div>
            <q-chip dense :color="availabilityColor" class="availability" text-color="white">
              {{ availabilityString }}
            </q-chip>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import date from 'utils/date-time'
export default {
  props: ['vehicle', 'selected', 'start', 'end'],
  data () {
    return {
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    unavailableSlots () {
      return this.vehicle.availability.filter(pa => !pa.is_available)
    },
    availability () {
      const unavailableSlotsInSelection = this.unavailableSlots.filter(s => {
        const slotDate = date.newDate(s.period_start.date)
        return slotDate >= this.start && slotDate < this.end
      })

      if (unavailableSlotsInSelection.length && unavailableSlotsInSelection.length === this.unavailableSlots.length) {
        return 'none'
      } else if (!unavailableSlotsInSelection.length) {
        return 'complete'
      } else {
        return 'partial'
      }
    },
    availabilityString () {
      if (this.availability === 'complete') return this.$t('carclub.fully_available')
      else if (this.availability === 'partial') return this.$t('carclub.partially_available')
      else return this.$t('not_available')
    },
    availabilityColor () {
      if (this.availability === 'complete') return 'green'
      else if (this.availability === 'partial') return 'orange'
      else return 'red'
    }
  },
  methods: {
    select () {
      this.$emit('selected', this.vehicle)
    }
  }
}
</script>

<style lang="stylus" scoped>
.details
  width 100%

.vehicle
  display flex
  transition transform .2s ease-in
  cursor pointer
  user-select none
  padding 8px!important

.car-image
  display flex
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding 8px

.car-image img
  display block

.bg-selected
  background #d7eef9

.vehicle-title
  text-transform capitalize
  width 100%
  display flex
  justify-content space-between

</style>
